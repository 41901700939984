<template>
  <main class="hire" data-color="#ede3dc">
    <section class="c-page-section">
      <div class="hire__item">
        <AppSlogan class="hire__big-title" ref="title" />

        <p class="hire__text" ref="text">
          Contact a selected organization in your country to ask for more information and support to get people with Down syndrome into your workplace.
        </p>
      </div>

      <div class="hire__item" ref="list">
        <ul
          v-for="(column, i) in city"
          :key="i"
          :class="`hire__list hire__list--${i === 0 ? 'left' : 'right'}`"
        >
          <li
            v-for="name in column"
            :key="name"
            @click="$store.commit('OPEN_HIRE_MODAL', name.toLowerCase())"
            class="hire-list__item"
            @mouseenter="mouseenter(name.toLowerCase())"
            @mouseleave="mouseleave"
          >
            <span class="u-split-container">
              <span class="split-line">
                {{ name }}
              </span>
            </span>
          </li>
        </ul>
      </div>

      <div class="hire__item">
        <button class="hire__add-button">
          <svg
            ref="circularButton"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 62 62"
            data-type="add"
            @mouseenter="rotatePlus('in')"
            @mouseleave="rotatePlus('out')"
            @click="$store.commit('OPEN_HIRE_MODAL', '')"
          >
            <circle fill="none" stroke="#000" stroke-width="1" cx="31" cy="31" r="29.7"/>
            <g ref="plus">
              <path fill="none" stroke="#000" stroke-width="2" d="M31,20v22"/>
              <path fill="none" stroke="#000" stroke-width="2" d="M20,31h22"/>
            </g>
          </svg>

          <div class="add-button__text" @click="$store.commit('OPEN_HIRE_MODAL')">
            <span class="button-text__first-line">Other</span>
            <span class="button-text__second-line">Countries</span>
          </div>
        </button>
      </div>

      <div class="hire__canvas" ref="canvas"/>
    </section>
  </main>
</template>

<script>
import { gsap } from 'gsap';
import AppSlogan from '@/components/AppSlogan.vue';
import { init, enter } from '@/scripts/animations';
import Flag from '@/stage/flag';
import raf from '@/scripts/raf';
import { isTouchDevice } from '@/scripts/utils';

export default {
  name: 'Hire',

  components: {
    AppSlogan,
  },

  data() {
    return {
      city: [
        ['Usa', 'Italy', 'Canada', 'Australia'],
        ['Uk', 'Brazil', 'Turkey', 'Switzerland'],
      ],
      rafAdded: false,
      flagTl: null,
      mouseCoord: { x: 0, y: 0 },
      moveCanvas: false,
      isTouchDevice: isTouchDevice(),
    };
  },

  mounted() {
    if (!this.isTouchDevice) {
      this.flag = new Flag(this.$refs.canvas);
      this.addEvents();
    }
  },

  methods: {
    rotatePlus(dir) {
      gsap.killTweensOf(this.$refs.plus);

      gsap.to(this.$refs.plus, {
        rotate: dir === 'in' ? 90 : 0,
        scale: 1,
        duration: 0.5,
        transformOrigin: '50% 50%',
        ease: 'coor',
      });
    },
    addEvents() {
      this.handlerMouseMove = (e) => this.mousemove(e);
      window.addEventListener('mousemove', this.handlerMouseMove);
    },
    enter() {
      init.get('bigTitle')(this.$refs.title.$el);
      init.get('fade')(this.$refs.text, { y: 35 });
      init.get('bigList')(this.$refs.list);
      init.get('circularButton')(this.$refs.circularButton);
      init.get('fade')(this.$el.querySelector('.button-text__first-line'), { y: 35 });
      init.get('titleSplit')(this.$el.querySelector('.button-text__second-line'));

      const tl = gsap.timeline();

      tl.addLabel('start')
        .add(enter.get('bigTitle')(this.$refs.title.$el), 'start')
        .add(enter.get('bigList')(this.$refs.list), 'start')
        .add(enter.get('fade')(this.$refs.text), 'start+=1')
        .add(enter.get('circularButton')(this.$refs.circularButton), 'start+=1')
        .add(enter.get('fade')(this.$el.querySelector('.button-text__first-line')), 'start+=1')
        .add(enter.get('titleSplit')(this.$el.querySelector('.button-text__second-line')), 'start+=1.2');

      return tl;
    },
    mouseenter(id) {
      if (this.isTouchDevice) return;
      if (this.$refs.canvas === undefined) return;
      this.rafAdded = true;
      this.flag.setFlag(id);
      raf.add((t) => this.render(t), 'flag-canvas');

      if (this.flagTl) this.flagTl.kill();
      this.flagTl = gsap.timeline();
      this.flagTl.addLabel('start')
        .set(this.$refs.canvas, { x: this.mouseCoord.x, y: this.mouseCoord.y }, 'start')
        .fromTo(this.$refs.canvas, {
          autoAlpha: 0,
          scale: 0,
        }, {
          autoAlpha: 1,
          scale: 1,
          ease: 'coor',
          duration: 0.5,
        }, 'start');

      this.moveCanvas = true;
    },
    mouseleave() {
      if (this.isTouchDevice) return;
      if (!this.flag.renderer) return;
      if (this.flagTl) this.flagTl.kill();

      this.flagTl = gsap.timeline();
      this.flagTl.addLabel('start')
        .to(this.$refs.canvas, {
          autoAlpha: 0,
          scale: 0,
          ease: 'coor',
          duration: 0.5,
        }, 'start');

      this.rafAdded = true;
      raf.remove('flag-canvas');
      this.flag.time = 0;

      this.moveCanvas = false;
    },
    mousemove(e) {
      if (!this.flag.renderer) return;

      this.mouseCoord = { x: e.clientX, y: e.clientY };

      if (this.moveCanvas) {
        gsap.to(this.$refs.canvas, {
          x: this.mouseCoord.x - (this.flag.renderer.width * 0.2),
          y: this.mouseCoord.y - (this.flag.renderer.height * 0.25),
          ease: 'coor',
          duration: 0.5,
        });
      }
    },
    render(t) {
      if (!this.flag.renderer) return;
      this.flag.render(t);
    },
  },

  beforeDestroy() {
    if (this.isTouchDevice) return;
    if (this.rafAdded) raf.remove('flag-canvas');
    this.flag.destroy();
    window.removeEventListener('mousemove', this.handlerMouseMove);
  },
};
</script>

<style lang="scss" scoped>
.hire {
  padding: 22vh 6.4vw 9.85vh;

  @include mq(ipadP) {
    // padding: 23.5vh 4vw 14.5vh;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    padding: 18.5vh 4vw 0;
  }

  &__item {
    grid-column: 1 / -1;

    @include mq($until: ipadP) {
      overflow: hidden;

      &:nth-child(2) {
        margin-top: 9.85vh;
      }
      &:nth-child(3) {
        padding-top: 3.75vh;
      }
    }

    @include mq(ipadP) {
      &:nth-child(1) {
        grid-column: 3 / 9;
      }
      &:nth-child(2) {
        align-self: end;
        grid-column: 9 / -1;
      }
      &:nth-child(3) {
        grid-column: 9 / -1;
        padding: 3.75vh 0 0 2.8vw;
      }
    }
  }

  &__big-title {
    margin-bottom: 2.7vh;
  }

  &__section-title {
    width: min-content;
    margin-bottom: 8.8vh;

    @include fluid-type(1440px, 3840px, 47px, 146.66px, 60px, 150px);
  }

  &__text {
    width: 100%;

    @include mq(ipadP) {
      width: 80%;
    }

    @include mq(ipadL) {
      width: 75%;
    }

    @include mq(ds) {
      width: 60%;
    }
  }

  &__list {
    display: block;
    margin-left: 2.8vw;

    text-transform: uppercase;
    @extend %font-hire-list;

    li {
      width: min-content;
      margin-bottom: 2.82vh;

      @include hover-underline(-2px, 4px, $black);
    }

    @include mq(ipadP) {
      display: inline-block;

      li {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__add-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      width: 4.3vw;
      min-width: 62px;

      cursor: pointer;
    }

    .add-button__text {
      text-align: left;
      margin-left: 20px;

      cursor: pointer;

      > span {
        display: block;

        &.button-text__first-line {
          font-family: 'Tropiline';
          @include fluid-type(1440px, 3840px, 15px, 40px, 26px, 58px);
        }
        &.button-text__second-line {
          text-transform: uppercase;
          font-family: $font-family-h;
          @include fluid-type(1440px, 3840px, 30px, 80px, 40px, 100px);
        }
      }
    }
  }

  &__canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 5vw;
    height: 3.5vw;

    pointer-events: none;

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
