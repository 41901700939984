<template>
  <h1 class="c-slogan" :class="{ 'c-slogan--variant' : variant }">
    <span class="u-split-container">
      <span class="split-line">
        {{ variant ? 'Invite' : 'Hire' }}
      </span>
    </span>
    <span class="u-split-container">
      <span class="split-line">
        {{ variant ? 'someone' : 'someone,' }}
      </span>
    </span>
    <span class="u-split-container">
      <span class="split-line">
        {{ variant ? 'to make' : 'with Down' }}
      </span>
    </span>
    <span class="u-split-container">
      <span class="split-line">
        {{ variant ? 'a change' : 'syndrome.' }}
      </span>
    </span>
  </h1>
</template>

<script>
export default {
  name: 'AppSlogan',

  props: {
    variant: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.c-slogan {
  @extend %font-slogan;

  &.c-slogan--variant {
    > span {
      display: block;

      &:nth-child(1) {
        transform: translateX(10%) translateY(10%);
      }
      &:nth-child(2) {
        transform: translateX(2%) translateY(-15%);
      }
      &:nth-child(3) {
        transform: translateX(12%) translateY(-20%);
      }
    }
  }

  > span {
    display: block;

    &:nth-child(1) {
      transform: translateX(22%) translateY(10%);
    }
    &:nth-child(2) {
      transform: translateX(5%) translateY(-15%);
    }
    &:nth-child(3) {
      transform: translateX(15%) translateY(-20%);
    }
    &:nth-child(4) {
      transform: translateY(-30%);
    }
  }
}
</style>
